<template>
    <v-parallax
    :src="hero2"
    height="250"
  >
    <v-row class="fill-height text-center" align="center" justify="center">
      <v-col :cols="isMobileView ? 8 : 8">
        <p class="mt-5 text-white">About Us</p>
        <h1 class="mb-2 text-white">Las Casas Gaming, Inc.</h1>
        <v-divider class="my-2" color="white" thickness="2"></v-divider>
        <p class="text-white mb-1 text-justify">Established in 2022, Las Casas Gaming, Inc. is a dynamic and innovative gaming company dedicated to delivering exceptional experiences to its customers. Specializing in sports betting, the company is poised to expand its portfolio with the introduction of e-games and an array of exciting specialty games, catering to a diverse audience of gaming enthusiasts.</p>
      </v-col>
    </v-row>
  </v-parallax>

  <div class="py-6 align-center bg-white">
    <container>
      <v-row class="d-flex align-center justify-center">
        <!-- Left Side: Image -->
        <v-col cols="12" md="5" class="justify-center">
          <v-card class="elevation-5" max-width="500">
            <v-img
              :src="hero4"
              alt="Victoria Sports Tower"
              aspect-ratio="16/9"
              class="rounded"
            ></v-img>
          </v-card>
        </v-col>
  
        <!-- Right Side: Text -->
        <v-col cols="12" md="5" class="text-start">
          <div>
            <v-img 
              :src="history" 
              class="mb-2" 
              max-height="52" 
              max-width="52" 
              alt="Awards"
            />
            <p class="text-muted mb-1">Our Story</p>
            <h3 class="fw-bold">GameX Sports Betting</h3>
            <v-divider class="my-2" color="red" width="50"></v-divider>
            <p class="text-body-1 mb-2 text-justify">
              In December 2022, LCGI first introduced GameX Sports in the field of sports betting that changed the sports entertainment in the Philippines. From the traditional street-wise paper ending, the company transformed it into digital basketball ending.
            </p>
            <p class="text-body-1 text-justify">
              LCGI is striving to provide users with the best possible gaming experience that includes fast, fun, and secure transactions.
            </p>
          </div>
        </v-col>
      </v-row>
    </container>
  </div>

  <div class="py-6 align-center bg-white">
    <container>
      <v-row class="d-flex align-center justify-center">
        <!-- Left Side: Image -->
        <v-col cols="12" md="5" class="justify-center">
          <v-card class="elevation-5" max-width="500">
            <v-img
              :src="mpbl"
              alt="Victoria Sports Tower"
              aspect-ratio="16/9"
              class="rounded"
            ></v-img>
          </v-card>
        </v-col>
  
        <!-- Right Side: Text -->
        <v-col cols="12" md="5" class="text-start">
          <div>
            <v-img 
              :src="press" 
              class="mb-2" 
              max-height="52" 
              max-width="52" 
              alt="Awards"
            />
            <p class="text-muted mb-1">Latest News</p>
            <h3 class="fw-bold">GameX Sports: The Official Online Gaming Partner of 2024 MPBL Regular Season</h3>
            <v-divider class="my-2" color="red" width="50"></v-divider>
            <p class="text-body-1 mb-2 text-justify">
              The new season of the MPBL is about to begin, and GameX Sports, "The First and Original Online Basketball Ending in the Philippines," is announced as its official online gaming partner. This partnership brings a thrilling mix of sports and gaming for fans nationwide.
            </p>
          </div>
        </v-col>
      </v-row>
    </container>
  </div>

    <v-divider></v-divider>

  <div class="py-5 bg-white">
    <v-container>
      <v-row class="d-flex justify-space-evenly align-start">
        <!-- Support Section -->
        <v-col cols="12" md="5" class="align-center text-center">
          <!-- <v-icon class="mb-2" size="48" color="#FFBB00FF">mdi-lightbulb-on-outline</v-icon> -->
          <v-img 
          :src="awards" 
          class="mb-2" 
          max-height="52" 
          max-width="100%" 
          alt="Awards"
        />
          <h3 class="mb-2">Our Vision</h3>
          <p class="text-justify">Las Casas Gaming’s vision is to be the leading sports betting company in the industry by providing its customers with the best odds, and exceptional services. Las Casas Gaming provides a platform to its customers to bet on their favorite team yielding the best odds, with fast payout and secure servers. Las Casas Gaming’s unrelenting dedication to sports betting will allow the company to deliver phenomenal gaming experience in the regulated online sports betting industry in the Philippines.</p>
        </v-col>

        <v-divider vertical inset></v-divider>
  
        <!-- Sales Section -->
        <v-col cols="12" md="5" class="text-center">
          <!-- <v-icon class="mb-2" size="48" color="#FF0000FF">mdi-bullseye-arrow</v-icon> -->
          <v-img 
          :src="goals" 
          class="mb-2" 
          max-height="52" 
          max-width="100%" 
          alt="Awards"
        />
          <h3 class="mb-2">Our Mission</h3>
          <p class="text-justify">Las Casas Gaming’s mission is to offer to its customers the most entertaining and responsible sports betting experience through its platform and services, while effectively championing the growth of the business and its team.</p>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <div class="bg-white">
    <v-row class="fill-height text-center py-6" align="center" justify="center">
      <v-col :cols="isMobileView ? 8 : 9">
        <!-- <v-icon class="mb-2" size="48" color="#008CFFFF">mdi-trophy-award</v-icon> -->
        <v-img 
        :src="trophy" 
        class="mb-2" 
        max-height="52" 
        max-width="100%" 
        alt="Awards"
      />
        <h3 class="mb-2">Our Core Values</h3>
        <v-divider class="my-2" color="black" thickness="2"></v-divider>
        <p class="py-6 text-justify">In order to achieve its mission and vision, Las Casas Gaming believes in the values they abide by. These values act as path for the company’s decisions and actions. Professionalism - Las Casas Gaming promotes professional and responsible gaming for its customers and encourages a professional working environment based on respect for its employees. Integrity - Las Casas Gaming conducts its business in an ethically and responsibly. The company adheres to responsible gaming as its reputation and honor counts.</p>
      </v-col>
    </v-row>
  </div>

  <div class="py-12">
    <v-row class="fill-height py-6" align="center" justify="center">
      <v-img 
        :src="nsjbi" 
        class="mb-2 mx-2" 
        max-height="100" 
        max-width="100" 
        alt="nsjbi"
      />
      <v-img 
        :src="lascasas" 
        class="mb-2 mx-2" 
        max-height="100" 
        max-width="100" 
        alt="lascasas"
      />
      <v-img 
        :src="vs2" 
        class="mb-2 mx-2" 
        max-height="100" 
        max-width="100" 
        alt="vs2"
      />
      <v-img 
        :src="bhomes" 
        class="mb-2 mx-2" 
        max-height="100" 
        max-width="100" 
        alt="bhomes"
      />
      <v-img 
        :src="pagcor" 
        class="mb-2 mx-2" 
        max-height="100" 
        max-width="100" 
        alt="pagcor"
      />
      <v-img 
        :src="mpbl1" 
        class="mb-2 mx-2" 
        max-height="100" 
        max-width="100" 
        alt="mpbl1"
      />
    </v-row>
  </div>
  
</template>

<script>
import hero2 from '@/assets/hero2.jpg'
import hero3 from '@/assets/hero3.png'
import hero4 from '@/assets/hero4.jpeg'
import mpbl from '@/assets/mpbl.jpg'
// 
import nsjbi from '@/assets/nsjbi.png'
import lascasas from '@/assets/lascasas.png'
import vs2 from '@/assets/vs2.png'
import bhomes from '@/assets/bhomes.png'
import pagcor from '@/assets/pagcor.png'
import mpbl1 from '@/assets/mpbl1.png'
import awards from '@/assets/awards.gif'
import trophy from '@/assets/trophy.gif'
import goals from '@/assets/goals.gif'
import history from '@/assets/history.gif'
import press from '@/assets/press.gif'

export default {
  data: () => ({
    hero2,
    hero3,
    hero4,
    mpbl,
    goals,
    awards,
    trophy,
    history,
    press,
    //
    nsjbi,
    lascasas,
    vs2,
    bhomes,
    pagcor,
    mpbl1,
  })
}
</script>