<template>
  <div class="bg-white">
    <v-container>
      <v-row class="d-flex justify-space-evenly align-center">
        <!-- Support Section -->
        <v-col cols="12" md="5" class="align-left text-left">
          <h1 class="mb-2">FAQs</h1>
          <p class="text-justify">Have questions? Here you’ll find the answers most valued by our partners, along with access to step-by-step instructions and support.</p>
        </v-col>

        <!-- Sales Section -->
        <v-col cols="12" md="5">
          <!-- <v-icon class="mb-2" size="48" color="#FF0000FF">mdi-bullseye-arrow</v-icon> -->
          <v-img 
          :src="interview" 
          class="mb-2" 
          max-height="350" 
          max-width="100%" 
          alt="interview"
        />
        </v-col>
      </v-row>
    </v-container>
  </div>

  <div>
    <v-container>
      <v-row class="d-flex justify-space-evenly align-center">
        <!-- Left Menu -->
        <v-col cols="3">
          <v-list dense class="bg-transparent">
            <v-list-item v-for="(item, index) in menuItems" :key="index">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
  
        <!-- Right Content -->
        <v-col cols="9">
          <h3 class="mb-2">Frequently Asked Questions</h3>
          <v-expansion-panels>
            <v-expansion-panel v-for="(question, index) in questions" :key="index">
              <v-expansion-panel-title>{{ question.title }}</v-expansion-panel-title>
              <v-expansion-panel-text>{{ question.content }}</v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import interview from '@/assets/interview.gif'

export default {
  data: () => ({
    interview,
    menuItems: [
        "About us",
        "Jobs",
        "Listings",
        "Employees",
        "Location",
        "Salaries",
      ],
      questions: [
        { title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor", content: "incididunt ut labore et dolore magna aliqua." },
        { title: "Ut enim ad minim veniam, quis nostrud", content: "exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { title: "Duis aute irure dolor in reprehenderit in voluptate", content: "velit esse cillum dolore eu fugiat nulla pariatur." },
        { title: "Excepteur sint occaecat cupidatat non proident,", content: "unt in culpa qui officia deserunt mollit anim id est laborum." },
      ],
  })
}
</script>