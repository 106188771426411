<template>
  <v-toolbar style="background: linear-gradient(50.5deg, #1C1C1CFF 27.9%, #404040FF 84.2%);">
    <v-toolbar-title>
      <v-img
        :src="require('@/assets/lcgi-logo.png')"
        max-width="220"
        min-width="150"
      ></v-img>
    </v-toolbar-title>

    <v-toolbar-items>
      <v-btn
        v-for="(item, index) in toolbarItems"
        :key="index"
        :to="{ name: item.to }"
        :prepend-icon="item.icon"
        stacked
        variant="text"
        size="small"
        color="white"
      >
        {{ item.name }}
      </v-btn>
    </v-toolbar-items>

    <v-divider class="border-opacity-25" vertical></v-divider>

    <div v-if="!authenticated" class="d-flex ga-2 mx-3">
      <v-btn :to="{ name: 'login' }" variant="outlined" rounded color="white"> Login </v-btn>
      <v-btn :to="{ name: 'register' }" variant="text" rounded color="white">
        Register
      </v-btn>
    </div>

    <v-menu width="256" rounded v-else>
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" class="ml-2">
          <v-avatar color="primary">
            <span>{{ initial }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-list>
          <v-list-item>
            <template v-slot:prepend>
              <v-avatar color="primary" size="large">
                <span class="text-h5">{{ initial }}</span>
              </v-avatar>
            </template>

            <v-list-item-title>
              {{ fullName }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-btn
              size="small"
              color="primary"
              block
              variant="tonal"
              :to="{ name: 'profile' }"
            >
              View Profile
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list density="compact">
          <v-list-item :to="{ name: 'jobApplications' }" color="primary">
            <template v-slot:prepend>
              <v-icon icon="mdi-briefcase-account-outline"></v-icon>
            </template>

            <v-list-item-title> Job Applications </v-list-item-title>
          </v-list-item>

          <div v-if="isEmployee">
            <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              :value="item"
              :to="{ name: item.to }"
              color="primary"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </div>

          <v-divider></v-divider>

          <v-list-item
            class="account-link text-red-darken-1 mt-1"
            @click="handleLogout"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-logout-variant"></v-icon>
            </template>

            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-tooltip text="Switch Portal" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" icon :to="{ name: 'dashboard' }" v-if="isAdmin">
          <v-icon color="white">mdi-swap-horizontal</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
  </v-toolbar>

  <LogoutDialog ref="logout" />
</template>

<script>
import { useDisplay } from "vuetify";
import { mapActions, mapGetters } from "vuex";
import LogoutDialog from "@/components/LogoutDialog";

export default {
  components: { LogoutDialog },

  data: () => ({
    display: useDisplay(),
    toolbarItems: [
      { name: "Jobs", to: "jobs", icon: "mdi-briefcase-variant" },
      { name: "About Us", to: "aboutUs", icon: "mdi-information" },
      { name: "FAQ", to: "faq", icon: "mdi-frequently-asked-questions" },
      { name: "Contact", to: "contact", icon: "mdi-phone" },
    ],
    menuItems: [
      { title: "Attendance", to: "attendance", icon: "mdi-clock-outline" },
      { title: "My Leaves", to: "myLeaves", icon: "mdi-file-document-outline" },
      { title: "My Payroll", to: "myPayroll", icon: "mdi-credit-card-outline" },
    ],
  }),

  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),

    isMobileView() {
      return this.display.smAndDown;
    },

    isAdmin() {
      if (this.authenticated && this.user.role_id === 1) {
        return true;
      }
    },

    isEmployee() {
      if (this.authenticated && this.user.role_id !== 3) {
        return true;
      }
    },

    initial() {
      if (this.user) {
        if (this.user.first_name) {
          return this.user.first_name.charAt(0);
        }

        return this.user.email.charAt(0);
      }
    },

    fullName() {
      if (this.user && this.user.first_name && this.user.last_name) {
        return this.user.first_name + " " + this.user.last_name;
      }
    },
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    async handleLogout() {
      this.$refs.logout.show();
      await this.logout();
      this.$router.push({ name: "jobs" });
      this.$refs.logout.hide();
    },
  },
};
</script>

<style scoped>
.menu-card {
  font-family: "Poppins", sans-serif !important;
}

.account-link:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
</style>