import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const icons = {
    defaultSet: 'mdi',
    aliases,
    sets: {
        mdi,
    }
}

const theme = {
    defaultTheme: 'light',
    themes: {
        dark: {
            dark: true,
            colors: {
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
        light: {
            dark: false,
            colors: {
                background: '#F5F5F5',
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
}

const vuetify = createVuetify({
    components,
    directives,
    icons,
    theme
})

export default vuetify