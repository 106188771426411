<template>
  <v-footer
    class="pa-5"
    style="
      background: linear-gradient(50.5deg, #1c1c1cff 27.9%, #404040ff 84.2%);
    "
  >
    <v-container>
      <v-row>
        <!-- Logo and Address Section -->
        <v-col cols="12" md="4">
          <v-img
            :src="require('@/assets/lcgi-logo.png')"
            max-width="220"
            min-width="150"
            class="mb-5"
          ></v-img>
          <p style="color: #fff; font-size: 12px; margin: 0">
            7th Floor 799 Victoria Sports Tower EDSA, Kamuning Brgy.,
          </p>
          <p style="color: #fff; font-size: 12px; margin: 0">
            South Triangle, Quezon City
          </p>
          <p style="color: #fff; font-size: 12px; margin-top: 5px;">support@gamexsports.com.ph</p>
          <p style="color: #fff; font-size: 12px; margin-top: 5px;">+639175983522</p>

          <div class="my-5">
            <v-btn
              v-for="icon in icons"
              :key="icon.title"
              :icon="icon.title"
              :color="icon.color"
              class="align-start"
              variant="text"
            ></v-btn>
          </div>
        </v-col>

        <!-- Frequently Asked Questions Section -->
        <v-col cols="12" md="4">
          <h5 class="font-weight-bold mb-4 text-white">
            Frequently Asked Questions
          </h5>
          <v-list dense class="bg-transparent text-white">
            <v-list-item v-for="(item, index) in faqs" :key="index">
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px;">
                  <v-icon small class="me-2">mdi-chevron-right</v-icon>
                  {{ item }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <!-- Newsletter Section -->
        <v-col cols="12" md="4">
          <h5 class="font-weight-bold mb-6 text-white">Newsletter</h5>
          <p style="color: #fff; font-size: 12px;" class="mb-5">
            We may send you information about related events, webinars,
            products, and services which we think you'll find interesting.
          </p>
          <v-row>
            <v-col cols="9">
              <v-text-field
                label="Enter your email address"
                dense
                hide-details
                variant="solo"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <v-btn color="white" class="white--text" rounded>
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Bottom Footer Section -->
      <v-divider class="my-4" color="white"></v-divider>
      <v-row justify="center" class="my-4">
        <p style="color: #fff">
          © {{ new Date().getFullYear() }} Las Casas Gaming, Inc. — HRIS
        </p>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        { title: "mdi-facebook", color: "white" },
        { title: "mdi-twitter", color: "white" },
        { title: "mdi-linkedin", color: "white" },
        { title: "mdi-instagram", color: "white" },
      ],
      faqs: [
        "Consectetur adipiscing elit?",
        "Integer cursus turpis ut sem laoreet?",
        "Dolor sit amet consectetur?",
      ],
    };
  },
};
</script>
